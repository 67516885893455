<template>
    <div id="pdfvuer">
        <div class="nav-document fixed-top d-flex align-items-center justify-content-between py-50">
            <div class="button-actions d-flex justify-content-star col-1 col-lg-4 align-items-center">
                <b-button id="return-button" size="sm" class="d-flex px-50" variant="primary" @click="$router.go(-1)">
                    <feather-icon class="mr-50" icon="ChevronLeftIcon" />
                    <span>Voltar</span>
                </b-button>
            </div>

            <div class="document-title col-10 col-lg-4 d-flex justify-content-center">
                <h5 class="text-center text-white my-auto d-none d-md-block">{{ documentName }}</h5>
            </div>

            <div class="button-actions d-flex justify-content-end col-1 col-lg-4 align-items-center text-white">
                <div class="d-none d-lg-flex justify-content-between mr-4">
                    <div id="zoom-out-button" class="d-flex zoom-buttons" style="cursor: pointer" @click="zoom = false">
                        <feather-icon class="" icon="ZoomOutIcon" size="20" />
                    </div>
                    /
                    <div id="zoom-in-button" class="d-flex zoom-buttons" style="cursor: pointer" @click="zoom = true">
                        <feather-icon class="" icon="ZoomInIcon" size="20" />
                    </div>
                </div>

                <div>
                    <b-button size="sm" id="download-button" class="align-items-center d-flex justify-self-end" variant="primary" title="Download file" @click="download()">
                        <span class="mr-1">Salvar</span>
                        <feather-icon class="" icon="DownloadIcon" />
                    </b-button>
                </div>
            </div>
        </div>
        <div class="mx-auto py-3 py-lg-5 px-0 mx-0 col-12" :class="{'col-lg-8': !zoom, 'col-lg-10': zoom}">
            <div class="mt-3 mt-md-1 d-flex align-items-center justify-content-around col-7 col-lg-4 mx-auto">
                <div class="bg-primary rounded-circle page-controlers d-flex justify-content-center align-items-center" id="previous-control" style="cursor: pointer" @click="page > 1 ? page-- : 1">
                    <div id="previous-button" class="text-white d-flex pages-buttons">
                        <feather-icon icon="ChevronLeftIcon" size="20" />
                    </div>
                </div>

                <div>{{ page }} / {{ numPages ? numPages : "∞" }}</div>

                <div class="bg-primary rounded-circle page-controlers d-flex justify-content-center align-items-center" id="next-control" style="cursor: pointer" @click="page < numPages ? page++ : 1">
                    <div id="next-button" class="text-white d-flex pages-buttons rounded">
                        <feather-icon icon="ChevronRightIcon" size="20" />
                    </div>
                </div>
            </div>
            <pdf :src="data" :page="page" style="margin: 20px auto" :annotation="true" :resize="true" @link-clicked="handle_pdf_link" class="mx-auto w-100 border">
                <template slot="loading">
                    <div class="d-flex justify-content-center align-items-center">
                        Carregando...
                    </div>
                </template>
            </pdf>
        </div>
    </div>
</template>

<script>
    import pdfvuer from "pdfvuer"
    import Vue from "vue"
    import router from "@/router"
    import {BootstrapVue} from "bootstrap-vue"
    Vue.use(BootstrapVue)

    export default {
        props: {
            data: {
                type: String,
                required: true,
            },
            documentName: {
                type: String,
            },
        },
        components: {
            pdf: pdfvuer,
        },
        data() {
            return {
                page: 1,
                numPages: 0,
                pdfdata: undefined,
                errors: [],
                zoom: false,
            }
        },
        mounted() {
            this.getPdf()
        },

        methods: {
            handle_pdf_link: function(params) {
                // Scroll to the appropriate place on our page - the Y component of
                // params.destArray * (div height / ???), from the bottom of the page div
                var page = document.getElementById(String(params.pageNumber))
                page.scrollIntoView()
            },
            getPdf() {
                if (!this.data) {
                    router.go(-1)
                }
                var self = this
                self.pdfdata = pdfvuer.createLoadingTask(this.data)
                self.pdfdata.then(pdf => {
                    self.numPages = pdf.numPages
                })
            },
            download() {
                const fileName = this.documentName + ".pdf"
                const linkSource = this.data
                var FileSaver = require("file-saver")
                FileSaver.saveAs(linkSource, fileName)
            },
        },
    }
</script>
<style lang="css" scoped>
    .nav-document {
        z-index: 100;
        background: #161d31;
    }

    .zoom-buttons {
        margin: 0 1em;
    }

    .controlers {
        margin-top: 50vh;
        z-index: 100;
    }
    .pages-buttons {
        margin: 0 0.2em;
    }

    .page-controlers {
        width: 25px;
        height: 25px;
    }
</style>
